<template>
  <page container-type="centered">
    <page-row>
      <page-column white data-test="new-request">
        <h1>{{ $t('view.requests.title') }}</h1>

        <div v-markdown="$t('view.request.new.message')"></div>

        <hr />

        <div class="mb-4">
          <h5>{{ $t('mooring.newRequest.title') }}</h5>
          <p>{{ $t('mooring.newRequest.description') }}</p>
          <por-btn
            variant="secondary"
            :to="{ name: 'new-request-mooring', query: $route.query }"
            data-test="outside-pet"
            >{{ $t('mooring.newRequest.button') }}</por-btn
          >
        </div>

        <div class="mb-4">
          <h5>{{ $t('repair.newRequest.title') }}</h5>
          <p>{{ $t('repair.newRequest.description') }}</p>
          <por-btn
            variant="secondary"
            :to="{ name: 'new-request-repair', query: $route.query }"
            data-test="request-repair"
            >{{ $t('repair.newRequest.button') }}
          </por-btn>
        </div>

        <div class="mb-4">
          <h5>{{ $t('transport.newRequest.title') }}</h5>
          <p>{{ $t('transport.newRequest.description') }}</p>
          <por-btn
            variant="secondary"
            :to="{ name: 'new-request-transport', query: $route.query }"
            data-test="transport"
            >{{ $t('transport.newRequest.button') }}
          </por-btn>
        </div>

        <div class="mb-4" v-if="hasFeatureOudeMaas">
          <h5>{{ $t('oudemaas.newRequest.title') }}</h5>
          <p>{{ $t('oudemaas.newRequest.description') }}</p>
          <por-btn variant="secondary" :to="{ name: 'new-request-oudemaas', query: $route.query }" data-test="oude-maas"
            >{{ $t('oudemaas.newRequest.button') }}
          </por-btn>
        </div>

        <hr />

        <div class="d-flex align-items-center mb-5 mt-4">
          <div class="mr-4">
            <img class="logo" src="~@/assets/images/placeholder-logo.png" alt="" />
          </div>
          <div>
            <h4 class="mb-1">{{ $t('view.request.new.other.title') }}</h4>
            <a
              href="https://www.portofrotterdam.com/nl/scheepvaart/zeevaart/meldingen-en-ontheffingen/formulieren-en-checklists-zeevaart"
              target="_blank"
            >
              {{ $t('view.request.new.other.link') }}
            </a>
          </div>
        </div>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { mapGetters } from 'vuex';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import Page from '../../components/page/page';

export default {
  components: {
    Page,
    PageRow,
    PageColumn,
    PorBtn,
  },

  computed: {
    ...mapGetters({
      hasFeatureOudeMaas: 'FEATURE_OUDE_MAAS',
    }),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 80px;
  height: auto;
}
</style>
